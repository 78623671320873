import { Component, lazy, Suspense } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import * as Constants from 'resources/constants'
import ThemeWrapper from '../themeWrapper'
import ScrollToTop from './ScrollToTop'

import { PersistGate } from 'redux-persist/integration/react'
import ConfigureStore from 'redux/store/configureStore'
import { Provider } from 'react-redux'

import LoginPage from 'views/login/loginPage'
import LoadingScreen from 'shared/loadingScreen'

const EmailVerificationPage = lazy(() => import('views/emailVerification/emailVerificationPage'))
const CreateAccountPage = lazy(() => import('views/createAccount/createAccountPage'))
const CreateCompanyAccountPage = lazy(() => import('views/createCompanyAccount/createCompanyAccountPage'))
const RootPage = lazy(() => import('views/root/rootPage'))
const ProjectsPage = lazy(() => import('views/Projects/projectsPage'))
const ProjectPage = lazy(() => import('views/project/projectPage'))
const ManageUsersPage = lazy(() => import('views/manageUsers/manageUsersPage'))
const ManageTagsPage = lazy(() => import('views/manageTags/manageTagsPage'))
const EditProfile = lazy(() => import('views/editProfile/profilePage'))
const TimelinePage = lazy(() => import('views/timeline/timelinePage'))
const DownloadPage = lazy(() => import('views/downloadPage/downloadPage'))
const SubscriptionPage = lazy(() => import('views/wallet/walletPage'))
const MessagesPage = lazy(() => import('views/messages/messagesPage'))
const CalendarPage = lazy(() => import('views/calendar/calendarPage'))
const CompanySettingsPage = lazy(() => import('views/companySettings/companySettingsPage'))
const AdminAnalyticsPage = lazy(() => import('views/companyAnalytics/companyAnalyticsPage'))

const { persistor, store } = ConfigureStore()

class routes extends Component {
    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeWrapper>
                        <BrowserRouter>
                            <ScrollToTop>
                                <Suspense fallback={<LoadingScreen />}>
                                    <Switch>
                                        <Route exact path="/" component={LoginPage} />
                                        <Route path={Constants.loginPage} component={LoginPage} />
                                        <Route path={Constants.emailVerificationPage} component={EmailVerificationPage} />
                                        <Route path={Constants.createAccountPage} component={CreateAccountPage} />
                                        <Route path={Constants.createCompanyAccountPage} component={CreateCompanyAccountPage} />
                                        <Route path={Constants.timelinePage} component={TimelinePage} />
                                        <Route path={Constants.downloadPage} component={DownloadPage} />

                                        <RootPage>
                                            <Switch>
                                                <Route path={Constants.projectsPage} component={ProjectsPage} />
                                                <Route path={Constants.projectPage} component={ProjectPage} />
                                                <Route path={Constants.manageUsersPage} component={ManageUsersPage} />
                                                <Route path={Constants.manageTagsPage} component={ManageTagsPage} />
                                                <Route path={Constants.profilePage} component={EditProfile} />
                                                <Route path={Constants.subscriptionPage} component={SubscriptionPage} />
                                                <Route path={Constants.messagesPage} component={MessagesPage} />
                                                <Route path={Constants.calendarPage} component={CalendarPage} />
                                                <Route path={Constants.companySettingsPage} component={CompanySettingsPage} />
                                                <Route path={Constants.adminAnalyticsPage} component={AdminAnalyticsPage} />
                                            </Switch>
                                        </RootPage>
                                    </Switch>
                                </Suspense>
                            </ScrollToTop>
                        </BrowserRouter>
                    </ThemeWrapper>
                </PersistGate>
            </Provider>
        )
    }
}

export default routes
