import * as Types from 'resources/types'
import firebase from 'FirebaseConfig'
import { timelineActions } from '../actions/timelineActions'

const TimelineReducer = (state: Types.timelineProjectReduxState = {
    timelineProject: {},
    allTimelinePosts: [],
    timelineCompany: {},
}, action: timelineActions) => {
    
    switch (action.type) {
        
        case 'GET_PROJECT_SUCCESS':
            
            state = {
                ...state,
                timelineProject: action.project,
            }

            break

        case 'GET_COMPANY_SUCCESS':
            
            state = {
                ...state,
                timelineCompany: action.company,
            }

            break

        case 'GET_POSTS_SUCCESS':
            //construct the firebase timestamp if the createdOn value isn't a firestore timestamp
            //this would happen if the timeline had a password on it
            for (var i = 0, len = action.posts.length; i < len; i++) {
                if (action.posts[i].createdOn && typeof action.posts[i].createdOn.toDate !== 'function') {
                    action.posts[i].createdOn = new firebase.firestore.Timestamp((action.posts[i].createdOn as any)['_seconds'], (action.posts[i].createdOn as any)['_nanoseconds'])
                }
            }

            state = {
                ...state,
                allTimelinePosts: action.posts,
            }

            break

        case 'CLEAR_DATA_SUCCESS':
            
            state = {
                timelineProject: {},
                allTimelinePosts: [],
                timelineCompany: {},
            }
            break

        default:
            break
    }

    return state
}

export default TimelineReducer
