import { Component } from 'react'

import firebase from 'FirebaseConfig'
import * as Constants from 'resources/constants'
import { loginAnalytics, resendVerificationEmail } from 'api/NetworkManager'

import { withRouter, RouteComponentProps } from 'react-router-dom'

import { connect } from 'react-redux'
import * as userActions from 'redux/actions/userActions'
import * as companiesActions from 'redux/actions/companyActions'

import Login from './components/login'
import ResetPasswordDialog from './components/resetPasswordDialog'
import SelectCompanyDialog from './components/selectCompanyDialog'
import VerificationNeededDialog from './components/verificationNeededDialog'
import GenericDialog from 'shared/genericDialog'
import GenericSnackbar from 'shared/genericSnackbar'

//used for types
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from 'redux/reducers/index'
import * as Types from 'resources/types'

interface OwnProps extends RouteComponentProps<any> {
    currentUser: Types.User
    companies: Types.companyReduxState
}

interface State {
    username: string
    password: string
    shouldShowErrorDialog: boolean
    errorObject: Types.errorObject
    shouldShowAccountNeedsVerifiedDialog: boolean
    shouldShowforgotPasswordDialog: boolean
    forgotPassword: string
    shouldShowCompanyListDialog: boolean
    arrayOfCompanies: Types.Company[]
    shouldShowSnackBar: boolean
    snackBarMessage: string
    loading: boolean
    selectedCompanyID: string
    redirectURL?: string
}

interface DispatchProps {
    getOfficesForUser: () => Promise<void>
    setCurrentCompany: (companyID: string) => Promise<void>
    getEndUserDataForCurrentCompany: (companyID: string) => Promise<void>
}

interface StateProps {
    currentUser: Types.User
    companies: Types.companyReduxState
}

type Props = StateProps & OwnProps & DispatchProps

let unsubscribe: firebase.Unsubscribe

class LoginPage extends Component<Props, State> {
    state: State = {
        username: '',
        password: '',
        shouldShowErrorDialog: false,
        errorObject: { title: '', message: '' },
        shouldShowAccountNeedsVerifiedDialog: false,
        shouldShowforgotPasswordDialog: false,
        forgotPassword: '',
        shouldShowCompanyListDialog: false,
        arrayOfCompanies: [],
        shouldShowSnackBar: false,
        snackBarMessage: '',
        loading: false,
        selectedCompanyID: '',
        redirectURL: this.props.location.redirectURL,
    }

    componentDidMount() {
        //check if user is signed in
        unsubscribe = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                // User is signed in.

                if (user.emailVerified) {
                    this.setState({ loading: true })

                    this.props.getOfficesForUser().then(_ => {
                        //deal with the connected companies
                        if (this.props.companies.connectedCompanies && this.props.companies.connectedCompanies.length > 1) {
                            this.setState((prevState, props) => ({
                                shouldShowCompanyListDialog: true,
                                selectedCompanyID: props.companies.connectedCompanies![0].id!,
                                loading: false,
                            }))
                        } else if (this.props.companies.connectedCompanies && this.props.companies.connectedCompanies.length === 1) {
                            this.setState((prevState, props) => ({
                                selectedCompanyID: props.companies.connectedCompanies![0].id!,
                                loading: false,
                            }))
                            this.navToApp()
                        } else {
                            //no active companies
                            this.setState({
                                shouldShowErrorDialog: true,
                                errorObject: {
                                    title: 'No Active Company',
                                    message: "We couldn't find any active companies attached to your profile. If you believe this is an error please contact the company you believe to be enrolled with.",
                                },
                                loading: false,
                            })
                        }
                    }).catch(error => {
                        this.setState({
                            errorObject: {
                                title: 'Error Retrieving Data',
                                message: error.message,
                            },
                            shouldShowErrorDialog: true,
                            loading: false,
                        })
                    })
                } else {
                    this.setState({ shouldShowAccountNeedsVerifiedDialog: true })
                }
            } else {
                // No user is signed in.
            }
        })
        //end of checking if user is signed in
    }

    componentWillUnmount() {
        unsubscribe()
    }

    userNameTextChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        this.setState({ username: event.target.value })
    }

    passwordTextChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        this.setState({ password: event.target.value })
    }

    didPressEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            this.signIn()
        }
    }

    navCreateAccount = () => {
        this.props.history.push(Constants.createAccountPage)
    }

    signIn = async () => {
        const userName = this.state.username
        const password = this.state.password

        if (!userName || !password) {
            this.setState({
                shouldShowErrorDialog: true,
                errorObject: {
                    title: 'Missing Information',
                    message: 'Please enter your email and password before logging in.',
                },
                password: '',
            })
            return
        }

        try {
            this.setState({ loading: true })

            await Promise.all([
                firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION),
                firebase.auth().signInWithEmailAndPassword(userName, password),
            ])

            this.setState({ loading: false })
        } catch (error) {
            // Handle Errors here.
            const errorCode = error.code
            const errorMessage = error.message
            // [START_EXCLUDE]
            if (errorCode === 'auth/wrong-password') {
                this.setState({
                    shouldShowErrorDialog: true,
                    errorObject: {
                        title: '',
                        message: 'The username or password does not match our records',
                    },
                    loading: false,
                    password: '',
                })
            } else {
                this.setState({
                    shouldShowErrorDialog: true,
                    errorObject: { title: '', message: errorMessage },
                    loading: false,
                    password: '',
                })
            }
        }
    }

    navToApp = async () => {
        try {
            this.setState({ shouldShowCompanyListDialog: false })

            this.props.setCurrentCompany(this.state.selectedCompanyID)

            this.setState({ loading: true })
            await Promise.all([
                loginAnalytics(this.state.selectedCompanyID),
                this.props.getEndUserDataForCurrentCompany(this.state.selectedCompanyID),
            ])

            this.setState({ loading: false })

            if (this.state.redirectURL) {
                this.props.history.push(this.state.redirectURL)
            } else {
                if (this.props.currentUser.isAccountOwner || this.props.currentUser.isAdmin || this.props.currentUser.isEmployee) {
                    this.props.history.push(Constants.projectsPage)
                } else {
                    //for clients
                    this.props.history.push(Constants.profilePage)
                }
            }
        } catch (error) {
            this.setState({
                errorObject: {
                    title: 'Error Retrieving Data',
                    message: error.message,
                },
                shouldShowErrorDialog: true,
                loading: false,
            })
        }
    }

    showforgotPasswordDialog = () => {
        this.setState({ shouldShowforgotPasswordDialog: true })
    }

    closeDialogs = () => {
        this.setState({
            shouldShowErrorDialog: false,
            shouldShowforgotPasswordDialog: false,
            shouldShowCompanyListDialog: false,
            shouldShowSnackBar: false,
        })
    }

    closeVerificationEmailDialog = () => {
        this.setState({ shouldShowAccountNeedsVerifiedDialog: false })
        firebase.auth().signOut()
    }

    sendVerificationEmail = async () => {
        this.setState({
            shouldShowSnackBar: true,
            snackBarMessage: 'Verification email sent',
            shouldShowAccountNeedsVerifiedDialog: false,
        })

        try {
            await resendVerificationEmail()
            firebase.auth().signOut()
        } catch (error) {
            this.setState({
                shouldShowSnackBar: true,
                snackBarMessage: 'There was an error sending verification email',
            })
            firebase.auth().signOut()
        }
    }

    sendForgotPasswordEmail = async (email: string) => {
        try {
            this.setState({
                shouldShowSnackBar: true,
                snackBarMessage: 'Password reset email sent',
                shouldShowforgotPasswordDialog: false,
            })

            await firebase.auth().sendPasswordResetEmail(email)
        } catch (error) {
            this.setState({
                shouldShowSnackBar: true,
                snackBarMessage: 'There was an error sending reset email.',
            })
        }
    }

    updateforgotPassword = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        this.setState({ forgotPassword: event.target.value })
    }

    companyRadioButtonChange = (event: any) => {
        this.setState({ selectedCompanyID: event.target.value })
    }

    render() {
        return (
            <div>
                <Login
                    userName={this.state.username}
                    password={this.state.password}
                    userNameTextChanged={this.userNameTextChanged}
                    passwordTextChanged={this.passwordTextChanged}
                    didPressEnter={this.didPressEnter}
                    navCreateAccount={this.navCreateAccount}
                    signIn={this.signIn}
                    showforgotPasswordDialog={this.showforgotPasswordDialog}
                    loading={this.state.loading}
                />

                {this.state.shouldShowforgotPasswordDialog && (
                    <ResetPasswordDialog
                        closeDialogs={this.closeDialogs}
                        shouldShowforgotPasswordDialog={this.state.shouldShowforgotPasswordDialog}
                        sendForgotPasswordEmail={this.sendForgotPasswordEmail}
                    />
                )}

                {this.state.shouldShowCompanyListDialog && (
                    <SelectCompanyDialog
                        shouldShowCompanyListDialog={this.state.shouldShowCompanyListDialog}
                        selectCompany={this.navToApp}
                        arrayOfCompanies={this.props.companies.connectedCompanies}
                        selectCompanyRadioButtonChange={this.companyRadioButtonChange}
                        selectedCompanyID={this.state.selectedCompanyID}
                    />
                )}

                {this.state.shouldShowAccountNeedsVerifiedDialog && (
                    <VerificationNeededDialog
                        shouldShowAccountNeedsVerifiedDialog={this.state.shouldShowAccountNeedsVerifiedDialog}
                        closeDialogs={this.closeVerificationEmailDialog}
                        sendVerificationEmail={this.sendVerificationEmail}
                    />
                )}

                {this.state.shouldShowErrorDialog && (
                    <GenericDialog
                        shouldShow={this.state.shouldShowErrorDialog}
                        closeDialog={this.closeDialogs}
                        title={this.state.errorObject.title}
                        message={this.state.errorObject.message}
                    />
                )}

                {this.state.shouldShowSnackBar && (
                    <GenericSnackbar
                        shouldShowSnackbar={this.state.shouldShowSnackBar}
                        message={this.state.snackBarMessage}
                        closeSnackbar={this.closeDialogs}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
    return {
        currentUser: state.currentUser.currentUser,
        companies: state.companies,
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: OwnProps): DispatchProps => {
    return {
        getOfficesForUser: async () => {
            await dispatch(companiesActions.getOfficesForUser())
        },
        setCurrentCompany: async (companyID: string) => {
            await dispatch(companiesActions.setCurrentCompany(companyID))
        },
        getEndUserDataForCurrentCompany: async (companyID: string) => {
            await dispatch(userActions.getEndUserDataForCurrentCompany(companyID))
        },
    }
}

export default connect<StateProps, DispatchProps, OwnProps, AppState>(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(LoginPage))
