import firebase from 'FirebaseConfig'
import * as Types from 'resources/types'

function initFireStore() {
    // Initialize Cloud Firestore through Firebase
    const db = firebase.firestore()
    return db
}

export async function getCompaniesFor() {
    const db = initFireStore()

    try {
        const user = firebase.auth().currentUser

        const companiesDoc = await db.collection('usernameCompanies').doc(user!.uid).get()

        if (!companiesDoc.exists) {
            throw new Error('The supplied company id did not match any company.')
        }

        const companies = companiesDoc.data() as Types.UsernameCompanies
        return companies.companyID
    } catch (error) {
        throw error
    }
}

export async function getCompanyObject(companyID: string) {
    const db = initFireStore()

    try {
        const companyDoc = await db.collection('companies').doc(companyID).get()

        if (!companyDoc.exists) {
            throw new Error('The supplied company id did not match any company.')
        }

        return companyDoc.data() as Types.Company
    } catch (error) {
        throw error
    }
}

export async function getEndUserInfoWith(companyID: string) {
    const db = initFireStore()

    try {
        const user = firebase.auth().currentUser

        const userData = await db.collection('companies').doc(companyID).collection('users').doc(user!.uid).get()

        if (!userData.exists) {
            throw new Error('The supplied user id did not match any user.')
        }

        return userData.data() as Types.User
    } catch (error) {
        throw error
    }
}

export async function getTimelineProject(companyID: string, projectID: string) {
    const db = initFireStore()

    try {
        const project = await db.collection('companies').doc(companyID).collection('projects').doc(projectID).collection('timelineProject').doc('timelineProject').get()

        if (!project.exists) {
            return {}
        }

        return project.data() as Types.TimelineProject
    } catch (error) {
        throw error
    }
}

export async function getProjects(companyID: string, sortBy: keyof Types.Project, sort: 'asc' | 'desc' = 'asc', limit = 20, folderID?: string, searchTerm?: string, lastDocument?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
    const db = initFireStore()

    try {

        let projectsCollectionRef

        if (!searchTerm || searchTerm === '') {
            if (folderID) {
                projectsCollectionRef = db.collection('companies').doc(companyID).collection('projects')
                    .where("folderID", "==", folderID)
                    .orderBy(sortBy.toString(), sort)
            } else {
                projectsCollectionRef = db.collection('companies').doc(companyID).collection('projects')
                    .orderBy(sortBy.toString(), sort)
            }
        } else {
            const encodedSearchTerm = await encodeAndTokenize(searchTerm)

            projectsCollectionRef = db.collection('companies').doc(companyID).collection('projects')
                .where("searchTerms", "array-contains-any", encodedSearchTerm)
                .orderBy(sortBy.toString(), sort)
        }

        if (lastDocument) {
            projectsCollectionRef = projectsCollectionRef.startAfter(lastDocument)
        }

        const projectsCollection = await projectsCollectionRef.limit(limit).get()

        if (projectsCollection.empty) {
            return []
        }

        const projects: Types.Project[] = projectsCollection.docs.map(doc => doc.data())

        return [projects, projectsCollection.docs[projectsCollection.docs.length - 1]] as [Types.Project[], firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>]
    } catch (error) {
        throw error
    }
}

export async function getTimelinePosts(companyID: string, projectID: string) {
    const db = initFireStore()

    try {
        const postsCollection = await db.collection('companies').doc(companyID).collection('projects').doc(projectID).collection('posts').orderBy('createdOn', 'desc').get()

        if (postsCollection.empty) {
            return []
        }

        const posts: Types.Post[] = postsCollection.docs.map(doc => doc.data())

        return posts
    } catch (error) {
        throw error
    }
}

export async function getAssetsForProject(companyID: string, projectID: string, postID: string) {
    const db = initFireStore()

    try {
        const assetsCollection = await db.collection('companies').doc(companyID).collection('projects').doc(projectID).collection('assets').where('postID', '==', postID).get()

        if (assetsCollection.empty) {
            return []
        }

        const assets: Types.Asset[] = assetsCollection.docs.map(doc => doc.data())

        return assets
    } catch (error) {
        throw error
    }
}

export async function getAllUsers(companyID: string) {
    const db = initFireStore()

    try {
        const userCollection = await db.collection('companies').doc(companyID).collection('users').get()

        if (userCollection.empty) {
            return []
        }

        const users: Types.User[] = userCollection.docs.map(doc => doc.data())

        return users
    } catch (error) {
        throw error
    }
}

export async function getTags(companyID: string, limit = 10, searchTerm?: string, lastDocument?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
    const db = initFireStore()

    try {

        let tagsCollectionRef

        if (!searchTerm || searchTerm === '') {
            tagsCollectionRef = db.collection('companies').doc(companyID).collection('tags')
                .orderBy('name', 'asc')
        } else {
            const encodedSearchTerm = await encodeAndTokenize(searchTerm)

            tagsCollectionRef = db.collection('companies').doc(companyID).collection('tags')
                .where("searchTerms", "array-contains-any", encodedSearchTerm)
                .orderBy('name', 'asc')
        }

        if (lastDocument) {
            tagsCollectionRef = tagsCollectionRef.startAfter(lastDocument)
        }

        const tagCollection = await tagsCollectionRef.limit(limit).get()

        if (tagCollection.empty) {
            return []
        }

        const tags: Types.Tag[] = tagCollection.docs.map(doc => doc.data())

        return [tags, tagCollection.docs[tagCollection.docs.length - 1]] as [Types.Tag[], firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>]
    } catch (error) {
        throw error
    }
}

// Posts
export async function createPost(companyID: string, post: Types.Post) {
    const db = initFireStore()

    try {

        //create the postupdate record 
        const recordRef = db.collection("companies").doc(companyID).collection('postUpdateRecords').doc()

        //get ref to the furture document
        const postRef = db.collection('companies').doc(companyID).collection('projects').doc(post.projectID).collection('posts').doc()

        //add the id to the project object
        post.id = postRef.id

        //fill in the post record object 
        const updateRecord = {
            updatedOn: firebase.firestore.FieldValue.serverTimestamp(),
            updatedByID: post.createdByID,
            projectID: post.projectID,
            postID: post.id,
        }

        await Promise.all([
            postRef.set(post),
            recordRef.set(updateRecord),
        ])

        return post
    } catch (error) {
        throw error
    }
}

export async function updateAPost(companyID: string, post: Types.Post) {
    const db = initFireStore()

    try {

        //create the postupdate record 
        const recordRef = db.collection("companies").doc(companyID).collection('postUpdateRecords').doc()

        //fill in the post record object 
        const updateRecord = {
            updatedOn: firebase.firestore.FieldValue.serverTimestamp(),
            updatedByID: post.updatedByID,
            projectID: post.projectID,
            postID: post.id,
        }

        //get ref to the furture document
        const postRef = db.collection('companies').doc(companyID).collection('projects').doc(post.projectID).collection('posts').doc(post.id)

        await Promise.all([
            postRef.update(post),
            recordRef.set(updateRecord),
        ])

        return
    } catch (error) {
        throw error
    }
}

export async function deleteAPost(companyID: string, post: Types.Post) {
    const db = initFireStore()

    try {
        //get ref to the furture document
        db.collection('companies').doc(companyID).collection('projects').doc(post.projectID).collection('posts').doc(post.id).delete()

        return
    } catch (error) {
        throw error
    }
}

// Message
export async function sendMessage(companyID: string, userUID: string, message: string, projectID: string, pointer?: Types.Pointer) {
    const db = initFireStore()

    try {
        //get ref to the furture document
        const messageRef = db.collection('companies').doc(companyID).collection('projects').doc(projectID).collection('messages').doc()

        //add the id to the project object
        const payload: Types.Message = {
            id: messageRef.id,
            body: message,
            pointer,
            sentByID: userUID,
        }

        //remove the pointer if undefined, because firebase doesn't like undefineded values
        pointer === undefined && delete payload.pointer

        await messageRef.set(payload)

        return payload
    } catch (error) {
        throw error
    }
}

//folders 
export async function getAllFolders(companyID: string) {
    const db = initFireStore()

    try {
        const folderCollection = await db.collection('companies').doc(companyID).collection('folders').get()

        if (folderCollection.empty) {
            return []
        }

        const folders: Types.Folder[] = folderCollection.docs.map(doc => doc.data() as Types.Folder)

        return folders

    } catch (error) {
        throw error
    }
}


/**
 * Encodes and tokenizes a string so fuzzy search will work with firestore queries
 */
export const encodeAndTokenize = async (rawString: string) => {

    try {

        const flexSearch = await import('flexsearch')

        //creating the FlexSearch object and encoding the string 
        const index = flexSearch.default.create({
            profile: 'match',
            encode: 'extra',
            tokenize: 'forward',
        }).encode(rawString)

        // //this splits up the encoded string
        const strArry = index.trim().split(' ');

        //remove any dups and extras, Firestore only allows 10 items in the array in queries
        const tokenizeArray = Array.from(new Set(strArry))
        tokenizeArray.splice(10)

        return tokenizeArray

    } catch (error) {
        throw error
    }

}