import * as Types from 'resources/types'
import { userActions } from '../actions/userActions'
import firebase from 'FirebaseConfig'

const userReducer = (state: Types.userReduxState = {
    currentUser: {},
    currentUserPhotoURL: undefined,
    currentProjects: [],
}, action: userActions) => {
    
    switch (action.type) {
        case 'GET_USER_DATA_SUCCESS':
            
            state = {
                ...state,
                currentUser: action.userData,
            }

            break

        case 'GET_USER_PROJECTS_SUCCESS':

            //construst the firebase timestamp
            for (var i = 0, len = action.userProjects.length; i < len; i++) {
                action.userProjects[i].createdOn = new firebase.firestore.Timestamp((action.userProjects[i].createdOn as any)["_seconds"], (action.userProjects[i].createdOn as any)["_nanoseconds"])
            }
            
            state = {
                ...state,
                currentProjects: action.userProjects,
            }

            break

        case 'USER_UPDATE_PROJECT_SUCCESS':
            
            const copiedAllPorjects = [...state.currentProjects]

            const projectIndex = copiedAllPorjects.findIndex(el => el.id === action.project.id)

            copiedAllPorjects[projectIndex] = { ...action.project }

            state = {
                ...state,
                currentProjects: copiedAllPorjects,
            }

            break

        case 'UPDATE_USER_SUCCESS':
            
            state = {
                ...state,
                currentUser: { ...action.userData },
            }

            break

        case 'UPDATE_USER_PHOTO_URL_SUCCESS':
            
            state = {
                ...state,
                currentUserPhotoURL: action.photoUrl,
            }

            break

        case 'SET_CURRENT_STATE_SIGNNED_OUT':
            
            state = {
                currentUser: {},
                currentUserPhotoURL: undefined,
                currentProjects: [],
            }
            break

        case 'ADD_COMPANY_CODE_TO_USER':
            break

        default:
            break
    }

    return state
}

export default userReducer
