import { FC } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import BuildsButton from './button'

interface GenericDialogProps {
    shouldShow: boolean
    title: string
    message: string
    closeDialog: () => void
    primaryButton?: JSX.Element
    secondaryButton?: JSX.Element
}

const GenericDialog: FC<GenericDialogProps> = ({
    shouldShow,
    title,
    message,
    closeDialog,
    primaryButton,
    secondaryButton,
}) => shouldShow ? (
    <Dialog
        open={shouldShow}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                {message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {primaryButton || (
                <BuildsButton title="OK" onClick={closeDialog} />
            )}
            {secondaryButton}
        </DialogActions>
    </Dialog>
) : null

export default GenericDialog
