import { FC } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import BuildsButton from 'shared/button'

interface VerificationNeededDialogProps {
    closeDialogs: () => void
    shouldShowAccountNeedsVerifiedDialog: boolean
    sendVerificationEmail: () => Promise<void>
}

const VerificationNeededDialog: FC<VerificationNeededDialogProps> = ({
    closeDialogs,
    shouldShowAccountNeedsVerifiedDialog,
    sendVerificationEmail,
}) => {
    return (
        <Dialog
            open={shouldShowAccountNeedsVerifiedDialog}
            onClose={closeDialogs}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                Account Needs Verified
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Your account has not yet been verified.
                    <br />
                    Would you like for us to resend a verification email ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <BuildsButton
                    title="Cancel"
                    onClick={closeDialogs}
                    variant="outlined"
                />
                <BuildsButton title="Resend" onClick={sendVerificationEmail} />
            </DialogActions>
        </Dialog>
    )
}

export default VerificationNeededDialog
