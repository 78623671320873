import * as Types from 'resources/types'
import firebase from 'FirebaseConfig'
import { usersActions } from '../actions/usersAcions'

const userReducer = (state: Types.usersReduxState = {
    allUsers: [],
}, action: usersActions) => {
    const allUsersCopy = [...state.allUsers]

    switch (action.type) {
        case 'GET_ALL_USER_DATA_SUCCESS':
            
            state = {
                ...state,
                allUsers: action.usersData,
            }

            break

        case 'CHANGE_USER_DATA_SUCCESS':
            
            const filteredUsers = allUsersCopy.filter(user => user.uid !== action.userData.uid)

            filteredUsers.push(action.userData)

            state = {
                ...state,
                allUsers: filteredUsers,
            }

            break

        case 'REMOVE_USER_DATA_SUCCESS':
            
            const filteredDeleteUsers = allUsersCopy.filter(user => user.uid !== action.userData.uid)

            state = {
                ...state,
                allUsers: filteredDeleteUsers,
            }

            break

        case 'SET_CURRENT_STATE_SIGNNED_OUT':
            
            state = {
                allUsers: [],
            }
            break

        case 'GET_ALL_USER_DATA_ANALYTICS_SUCCESS':
            
            //construst the firebase timestamp
            for (var i = 0, len = action.usersData.length; i < len; i++) {
                const tempUser = action.usersData[i]
                if (tempUser.lastUpdate) {
                    tempUser.lastUpdate = new firebase.firestore.Timestamp((tempUser.lastUpdate as any)['_seconds'], (tempUser.lastUpdate as any)['_nanoseconds'])
                }
            }

            state = {
                ...state,
                allUsers: action.usersData,
            }
            break

        default:
            break
    }

    return state
}

export default userReducer
