import * as types from 'resources/types'
import * as linkify from 'linkifyjs'

export const removeComma = (value?: string) => {
    if (typeof value === 'string') {
        return value.replace(/,/g, '')
    } else {
        return value
    }
}

export const stripAllButNumbers = (value?: string) => {
    if (typeof value === 'string') {
        return value.replace(/\D/g, '')
    } else {
        return value
    }
}

export const topCredentialOfUser = (user?: types.User) => {
    if (user) {
        if (user.isAccountOwner) {
            return 'Account Owner'
        } else if (user.isAdmin) {
            return 'Admin'
        } else if (user.isEmployee) {
            return 'Employee'
        } else {
            return 'Client'
        }
    } else {
        return user
    }
}

export const constructBuilderSideTimelineLink = (builderSideTimelineURL: string, projectID: string) => {
    let timelineLink = ''

    //check for and remove the "http://" or "https://"
    if (builderSideTimelineURL.includes('//')) {
        const slashIndex = builderSideTimelineURL.indexOf('//')
        builderSideTimelineURL = builderSideTimelineURL.substring(slashIndex + 2)
    }

    //add the http:// back on
    builderSideTimelineURL = `https://${builderSideTimelineURL}`

    // check for and add the ending "/"
    // this function may fire more than once so make sure not the add another ?pid=
    if (!builderSideTimelineURL.includes('?pid=')) {
        if (builderSideTimelineURL.charAt(builderSideTimelineURL.length - 1) !== '/') {
            timelineLink = `${builderSideTimelineURL}/?pid=${projectID}`
        } else {
            timelineLink = `${builderSideTimelineURL}?pid=${projectID}`
        }
    }

    return timelineLink
}

export const isURLVaild = (url?: string) => {
    if (typeof url === 'string') {
        return linkify.test(url, 'url')
    }
    return false
}

export const isEmailVaild = (email?: string) => {
    if (typeof email === 'string') {
        return linkify.test(email, 'email')
    }
    return false
}

export const isPhoneVaild = (phone?: string) => {
    const phoneNumberTest = /^\d{10}$/
    if (typeof phone === 'string' && stripAllButNumbers(phone)?.match(phoneNumberTest)) {
        return true
    }
    return false
}
