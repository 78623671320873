import { FC } from 'react'
import Snackbar from '@material-ui/core/Snackbar'

interface GenericSnackbarProps {
    shouldShowSnackbar: boolean
    message: string
    closeSnackbar: () => void
}

const GenericSnackbar: FC<GenericSnackbarProps> = ({
    shouldShowSnackbar,
    message,
    closeSnackbar,
}) => shouldShowSnackbar ? (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={shouldShowSnackbar}
            message={message}
            autoHideDuration={3000}
            onClose={closeSnackbar}
        />
    ) : null

export default GenericSnackbar
