import { FC } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(({ shape, breakpoints, direction, mixins, overrides, palette, props, shadows, spacing, transitions, typography, zIndex }: Theme) => ({
    rootContainer: (props: any) => ({
        backgroundColor: props.shouldBeForComponents ? 'transparent' : palette.background.default,
        minHeight: props.shouldBeForComponents ? '100%' : '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }),
    title: {
        fontWeight: typography.fontWeightLight,
        color: palette.text.primary,
    },
    circularProgress: {
        marginTop: spacing(2),
    },
}))

interface LoadingScreenProps {
    shouldBeForComponents?: boolean
}

const LoadingScreen: FC<LoadingScreenProps> = ({ shouldBeForComponents }) => {
    const classes = useStyles({ shouldBeForComponents })

    return (
        <div className={classes.rootContainer}>
            <Typography variant="subtitle1" className={classes.title}>
                Loading...
            </Typography>
            <CircularProgress
                size={50}
                thickness={1}
                color="primary"
                className={classes.circularProgress}
            />
        </div>
    )
}

export default LoadingScreen
