import { FC } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from './redux/reducers/index'
import {
    MuiThemeProvider,
    createMuiTheme,
    responsiveFontSizes,
} from '@material-ui/core/styles'

const ThemeWrapper: FC = ({ children }) => {
    const appSettings = useSelector((state: AppState) => state.appSettings)

    return (
        <MuiThemeProvider theme={responsiveFontSizes(createMuiTheme({
            overrides: {
                MuiPaper: {
                    rounded: {
                        borderRadius: 11,
                    },
                },

                MuiInputLabel: {
                    root: {},
                    focused: {},
                    shrink: {
                        marginTop: 6,
                    },
                    formControl: {
                        top: -6,
                        left: 0,
                        position: 'absolute',
                        transform: 'translate(0, 15px) scale(1)',
                    },
                },

                MuiOutlinedInput: {
                    root: {},
                    focused: {},
                    input: {
                        padding: 12,
                        fontSize: 14,
                    },
                },

                MuiButton: {
                    root: {
                        borderRadius: 11,
                    },
                    containedPrimary: {
                        color: 'white',
                    },
                },

                MuiDivider: {
                    middle: {
                        marginLeft: 0,
                        marginRight: 0,
                        width: '80%',
                    },
                },

                MuiSvgIcon: {
                    root: {
                        color: appSettings.darkTheme
                            ? '#c0c2c5'
                            : 'grey',
                        fontSize: 20,
                    },
                },

                MuiTabs: {
                    root: {
                        width: '100%',
                        borderRadius: 30,
                        background: appSettings.darkTheme
                            ? '#424242'
                            : '#EFEFF4',
                        padding: 0,
                        paddingLeft: 6,
                        paddingRight: 6,
                        boxShadow: '0px 3px 15px rgba(34, 35, 58, 0.5)',
                    },
                    indicator: {
                        height: '80%',
                        borderRadius: 25,
                        backgroundColor: appSettings.darkTheme
                            ? '#303030'
                            : '#fff',
                        marginBottom: 5,
                    },
                },

                MuiTab: {
                    root: {
                        zIndex: 1,
                    },
                },
            },

            palette: {
                primary: {
                    main: '#49B1F6',
                },
                secondary: {
                    main: appSettings.darkTheme ? '#ffb300' : '#0B62A6',
                },
                text: {
                    primary: appSettings.darkTheme ? 'rgba(255.0, 255.0, 255.0, 1.0)' : 'rgba(0.0, 0.0, 0.0, 1.0)',
                    secondary: appSettings.darkTheme ? 'rgba(235.0, 235.0, 245.0, 1.0)' : 'rgba(60.0, 60.0, 67.0, 1.0)',
                },
                background: {
                    default: appSettings.darkTheme ? 'rgba(0.0, 0.0, 0.0, 1.0)' : 'rgba(242.0, 242.0, 247.0, 1.0)',
                    paper: appSettings.darkTheme ? 'rgba(28.0, 28.0, 30.0, 1.0)' : 'rgba(255.0, 255.0, 255.0, 1.0)',
                },
                type: appSettings.darkTheme ? 'dark' : 'light',
            },

            typography: {
                fontFamily: [
                    '-apple-system',
                    'BlinkMacSystemFont',
                    'Roboto',
                    '"Open Sans"',
                    '"Helvetica Neue"',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                ].join(','),
                fontSize: 12,
                fontWeightLight: 200,
                fontWeightRegular: 300,
                fontWeightMedium: 500,
                fontWeightBold: 700,
                htmlFontSize: 14,
                h1: {
                    fontSize: '4.8rem',
                },
                h2: {
                    fontSize: '3rem',
                },
                h3: {
                    fontSize: '2.4rem',
                },
                h4: {
                    fontSize: '1.7rem',
                },
                h5: {
                    fontSize: '1.2rem',
                },
                h6: {
                    fontSize: '1rem',
                },
                subtitle1: {
                    fontSize: '0.8rem',
                },
                subtitle2: {
                    fontSize: '0.7rem',
                },
                body1: {
                    fontSize: '0.8rem',
                },
                body2: {
                    fontSize: '0.7rem',
                },
                button: {
                    fontSize: '0.7rem',
                },
                caption: {
                    fontSize: '0.6rem',
                },
                overline: {
                    fontSize: '0.6rem',
                },
            },
        }) )} >
            {children}
        </MuiThemeProvider>
    )
}

export default ThemeWrapper
