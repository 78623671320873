import { Fragment, FC } from 'react'

import Logo from 'assets/LogoIcon.svg'

import { makeStyles, Theme } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import BuildsButton from 'shared/button'

const useStyles = makeStyles(({ palette, spacing, breakpoints }: Theme) => ({
    root: {
        backgroundColor: palette.background.default,
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: spacing(2),

        [breakpoints.down('xs')]: {
            padding: 0,
            alignItems: 'stretch',
        },
    },
    paper: {
        backgroundColor: palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: 460,

        [breakpoints.down('xs')]: {
            width: '100vw',
        },
    },
    logoStyle: {
        margin: spacing(2),
        height: 75,
        [breakpoints.down('md')]: {
            height: 50,
        },
    },
    textFieldStyle: {
        width: 380,
        margin: spacing(1),
        [breakpoints.down('xs')]: {
            width: 280,
        },
    },
    loginButtonStyle: {
        margin: spacing(1),
        width: 380,
        height: '45px',
        [breakpoints.down('xs')]: {
            width: 280,
        },
    },
}))

interface LoginProps {
    loading: boolean
    userName: string
    password: string
    userNameTextChanged: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
    passwordTextChanged: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
    didPressEnter: (event: React.KeyboardEvent<HTMLDivElement>) => void
    showforgotPasswordDialog: () => void
    navCreateAccount: () => void
    signIn: () => Promise<void>
}

const Login: FC<LoginProps> = ({
    loading,
    userName,
    password,
    userNameTextChanged,
    passwordTextChanged,
    didPressEnter,
    showforgotPasswordDialog,
    navCreateAccount,
    signIn,
}) => {
    const classes = useStyles({})

    return (
        <Fragment>
            <div className={classes.root}>
                <Paper elevation={3} className={classes.paper}>
                    <a href={`https://${process.env.REACT_APP_DOMAIN}`}>
                        <img src={Logo} alt="logo" className={classes.logoStyle} />
                    </a>
                    <TextField
                        id="email"
                        label="Email"
                        type="username"
                        margin="normal"
                        className={classes.textFieldStyle}
                        onChange={userNameTextChanged}
                        variant="outlined"
                        value={userName}
                    />
                    <TextField
                        id="password"
                        label="Password"
                        type="password"
                        margin="normal"
                        className={classes.textFieldStyle}
                        onChange={passwordTextChanged}
                        variant="outlined"
                        autoComplete="current-password"
                        onKeyPress={didPressEnter}
                        value={password}
                    />

                    <div className={classes.loginButtonStyle}>
                        <BuildsButton
                            title="SignIn"
                            onClick={signIn}
                            size="large"
                            height="100%"
                            width="100%"
                            processing={loading}
                            processingTitle="Signing In"
                            centerProgress
                        />
                    </div>

                    <BuildsButton
                        title="Forgot Password"
                        onClick={showforgotPasswordDialog}
                        variant="text"
                    />

                    <Typography variant="body2" gutterBottom>
                        Don’t have a Builds360 account?
                        <BuildsButton
                            title="Sign up"
                            onClick={navCreateAccount}
                            variant="text"
                        />
                    </Typography>
                </Paper>
            </div>
        </Fragment>
    )
}

export default Login
