import firebase from 'FirebaseConfig'
import * as Types from 'resources/types'

import * as FirebaseManager from './FirebaseManager'

//create account funcs

export async function createAccount(code: string, user: Types.User, password: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncCreateAccount')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            code: code,
            email: user.email,
            password: password,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            shouldEmailNotifications: user.shouldEmailNotifications,
        })

        //return what the cloud function returned
        return result
    } catch (error) {
        throw error
    }
}

export async function resendVerificationEmail() {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncResendVerificationCode')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({})

        //return what the cloud function returned
        return result
    } catch (error) {
        throw error
    }
}

export async function verifyEmail(code: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncVerifyEmail')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            code,
        })

        //return what the cloud function returned
        return result
    } catch (error) {
        throw error
    }
}

export async function checkCompanyCode(companyCode: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncCheckCompanyCode')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyCode,
        })

        //return what the cloud function returned
        return result.data as Types.CompanyCode
    } catch (error) {
        throw error
    }
}

export async function addCompnayCodeToUser(companyID: string, companyCode: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncAddCompnayCodeToUser')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyCode,
            currentCompanyID: companyID,
        })

        //return what the cloud function returned
        return result.data
    } catch (error) {
        throw error
    }
}

export async function searchForCompanyNameMatch(companyName: string) {
    try {
        const response = await fetch(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${companyName}`)
        const data = await response.json()
        return data
    } catch (error) {
        throw error
    }
}

export async function createCompanyAccount(company: Types.Company, user: Types.User, password: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncCreateCompany')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyName: company.name,
            logoLink: company.logoLink,
            url: company.url,
            email: user.email,
            password: password,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            shouldEmailNotifications: user.shouldEmailNotifications,
        })

        //return what the cloud function returned
        return result
    } catch (error) {
        throw error
    }
}

//login funcs

export async function loginAnalytics(companyID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncLoginAnalytics')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
        })

        //return what the cloud function returned
        return result
    } catch (error) {
        throw error
    }
}

export async function getOfficesForUser() {
    try {
        //get the username company IDs
        const companyIDArray = await FirebaseManager.getCompaniesFor()

        //put the calls in an array
        const companyPromiseArray = []
        for (let id in companyIDArray) {
            companyPromiseArray.push(FirebaseManager.getCompanyObject(companyIDArray[id]))
        }

        //get company objects from the IDs
        const companies = await Promise.all(companyPromiseArray)

        //return what the cloud function returned
        return companies
    } catch (error) {
        throw error
    }
}

export async function getEndUserData(companyID: string) {
    try {
        //get end user data from db
        const endUserData = await FirebaseManager.getEndUserInfoWith(companyID)

        //return what the cloud function returned
        return endUserData
    } catch (error) {
        throw error
    }
}

//Projects funcs

export async function getUsersProjects(companyID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncGetUsersProjects')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
        })

        //return what the cloud function returned
        return result.data as Types.Project[]
    } catch (error) {
        throw error
    }
}

export async function userUpdateProject(companyID: string, project: Types.Project, password?: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncUserUpdateProject')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            password,
            shoudlPasswordProtectTimeline: project.passwordProtected,
            projectID: project.id,
        })

        //return what the cloud function returned
        return result
    } catch (error) {
        throw error
    }
}

export async function getTimelineProject(companyID: string, projectID: string) {
    try {
        //get all projects from the database
        const project = await FirebaseManager.getTimelineProject(companyID, projectID)

        //return what the all projects function returned
        return project
    } catch (error) {
        throw error
    }
}

export async function updateTimelineProjectAnalytics(companyID: string, projectID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncTimelineProjectAnalytics')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            projectID,
        })

        //return what the cloud function returned
        return result
    } catch (error) {
        throw error
    }
}

export async function getProjects(companyID: string, sortBy: keyof Types.Project, sort?: 'asc' | 'desc', limit?: number, folderID?: string, searchTerm?: string, lastDocument?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
    try {
        //get all projects from the database
        const projects = await FirebaseManager.getProjects(companyID, sortBy, sort, limit, folderID, searchTerm, lastDocument)

        //return what the all projects function returned
        return projects
    } catch (error) {
        throw error
    }
}

export const updateAProject = async (companyID: string, userUID: string, updatedProject: Types.Project, projectPhotoFile?: Types.CustomFile) => {

    try {

        if (projectPhotoFile && projectPhotoFile.image) {
            if (!projectPhotoFile.image.includes(process.env.REACT_APP_STORAGE_DOMAIN!)) {
                //if the image has already been uploaded, nothing to do
                //but if it gets here that means it still needs to be uploaded.

                // Points to the root reference
                const storageRef = firebase.storage().ref()

                // Points to this project's own image folder
                const ProjectImageFolderRef = storageRef.child('projectMainAssetTemp/' + projectPhotoFile.file!.name)

                // Create file metadata including the content type
                const metadata: firebase.storage.UploadMetadata = {
                    customMetadata: {
                        companyID: companyID,
                        projectID: updatedProject.id!,
                        userToken: userUID,
                    },
                }

                await ProjectImageFolderRef.put(projectPhotoFile.file!, metadata)
            }
        }

        await updateAProjectHandler(companyID, updatedProject)

        return updatedProject

    } catch (error) {
        throw error
    }

}

export async function updateAProjectHandler(companyID: string, updatedProject: Types.Project) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncUpdateAProject')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            projectID: updatedProject.id,
            state: updatedProject.state,
            projectName: updatedProject.projectName,
            projectTitle: updatedProject.title,
            projectTags: updatedProject.tags,
            projectNotes: updatedProject.notes,
            primaryEmail: updatedProject.primary ? updatedProject.primary.email : '',
            primaryName: updatedProject.primary ? updatedProject.primary.fullName : '',
            primaryNumber: updatedProject.primary ? updatedProject.primary.phoneNumber || '' : '',
            secondaryEmail: updatedProject.secondaryEmails,
            shoudlPasswordProtectTimeline: updatedProject.passwordProtected,
            folderID: updatedProject.folderID,
        })

        //return what the cloud function returned
        return result.data as Types.Project[]
    } catch (error) {
        throw error
    }
}

export const createAProject = async (companyID: string, userUID: string, project: Types.Project, projectPhotoFile?: Types.CustomFile) => {

    try {

        const newProject = await createAProjectHandler(companyID, project)

        if (projectPhotoFile && projectPhotoFile.file) {
            //there is a projectPhotoFile so save it

            // Points to the root reference
            const storageRef = firebase.storage().ref()

            // Points to this project's own image folder
            const ProjectImageFolderRef = storageRef.child('projectMainAssetTemp/' + projectPhotoFile.file.name)

            // Create file metadata including the content type
            const metadata: firebase.storage.UploadMetadata = {
                customMetadata: {
                    companyID: companyID,
                    projectID: newProject.id!,
                    userToken: userUID,
                },
            }
            await ProjectImageFolderRef.put(projectPhotoFile.file, metadata)
        }

    } catch (error) {
        throw error
    }

}

async function createAProjectHandler(companyID: string, project: Types.Project) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncCreateAProject')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            projectID: project.id,
            projectName: project.projectName,
            projectTitle: project.title,
            projectTags: project.tags,
            projectNotes: project.notes,
            projectPhotoID: project.photoURL,
            primaryEmail: project.primary ? project.primary.email : '',
            primaryName: project.primary ? project.primary.fullName : '',
            primaryNumber: project.primary ? project.primary.phoneNumber || '' : '',
            secondaryEmail: project.secondaryEmails,
            shoudlPasswordProtectTimeline: project.passwordProtected,
            folderID: project.folderID,
        })

        //return what the cloud function returned
        return result.data as Types.Project
    } catch (error) {
        throw error
    }
}

//Posts funcs

export async function getTimelinePosts(companyID: string, projectID: string) {
    try {
        //get all projects from the database
        const posts = await FirebaseManager.getTimelinePosts(companyID, projectID)

        //return what the all projects function returned
        return posts
    } catch (error) {
        throw error
    }
}

export async function getTimelinePostsWithPassword(companyID: string, projectID: string, password: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncGetTimelinePostsWithPassword')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            projectID,
            password,
        })

        //return what the cloud function returned
        return result.data as Types.Post[]
    } catch (error) {
        throw error
    }
}

export async function createAPost(companyID: string, post: Types.Post) {
    try {
        const newPost = await FirebaseManager.createPost(companyID, post)

        return newPost
    } catch (error) {
        throw error
    }
}

export async function updateAPost(companyID: string, post: Types.Post) {
    try {
        await FirebaseManager.updateAPost(companyID, post)

        return
    } catch (error) {
        throw error
    }
}

export async function deleteAPost(companyID: string, post: Types.Post) {
    try {
        await FirebaseManager.deleteAPost(companyID, post)

        return
    } catch (error) {
        throw error
    }
}

//Message funcs
export async function sendMessage(companyID: string, userUID: string, message: string, projectID: string, pointer?: Types.Pointer) {
    try {
        const newMessage = await FirebaseManager.sendMessage(companyID, userUID, message, projectID, pointer)

        return newMessage
    } catch (error) {
        throw error
    }
}

export async function getAllLastMessages(companyID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncGetAllRecentMessages')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
        })

        //return what the cloud function returned
        return result.data as Types.Message[]
    } catch (error) {
        throw error
    }
}

//Profile funcs

export async function editAccount(companyID: string, user: Types.User, password: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncEditAccount')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            email: user.email,
            password: password,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            shouldEmailNotifications: user.shouldEmailNotifications,
        })

        //return what the cloud function returned
        return result
    } catch (error) {
        throw error
    }
}

//Manage Users

export async function allUsers(companyID: string) {
    try {
        //get all users from the database
        const allUsers = await FirebaseManager.getAllUsers(companyID)

        return allUsers
    } catch (error) {
        throw error
    }
}

export async function createCodeAndSend(companyID: string, email: string, isAdmin: boolean, isEmployee: boolean, isAccountOwner: boolean) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncCreateCodeAndSend')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            emailAddress: email,
            isAdmin,
            isEmployee,
            isAccountOwner,
        })

        //return what the cloud function returned
        return result
    } catch (error) {
        throw error
    }
}

export async function removeUser(companyID: string, removeUserUID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncRemoveUser')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            removeUserUID,
        })

        //return what the cloud function returned
        return result
    } catch (error) {
        throw error
    }
}

export async function changeUserRole(companyID: string, changeUserUID: string, isAdmin: boolean, isEmployee: boolean, isAccountOwner: boolean) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncChangeUserRole')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            changeUserUID,
            isAccountOwner,
            isAdmin,
            isEmployee,
        })

        //return what the cloud function returned
        return result
    } catch (error) {
        throw error
    }
}

// tag funcs

export async function createATag(companyID: string, tagName: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncCreateATag')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            tagName,
        })

        //return what the cloud function returned
        return result.data as Types.Tag
    } catch (error) {
        throw error
    }
}

export async function getTags(companyID: string, limit?: number, searchTerm?: string, lastDocument?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
    try {
        //get all tags from the database
        const tags = await FirebaseManager.getTags(companyID, limit, searchTerm, lastDocument)

        return tags
    } catch (error) {
        throw error
    }
}

export async function removeTag(companyID: string, tagID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncRemoveTag')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            tagID,
        })

        //return what the cloud function returned
        return result
    } catch (error) {
        throw error
    }
}

//timeline

export async function getCompany(companyID: string) {
    try {
        const allTags = await FirebaseManager.getCompanyObject(companyID)

        return allTags
    } catch (error) {
        throw error
    }
}

//company settings

export async function updateCompany(company: Types.Company) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncUpdateCompany')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID: company.id,
            name: company.name,
            legalName: company.legalName,
            url: company.url,
            logoLink: company.logoLink,
            clientCode: company.clientCode,
            supportEmail: company.supportEmail,
            isAssetsEnabled: company.isAssetsEnabled,
            isCalendarEnabled: company.isCalendarEnabled,
            isMessagesEnabled: company.isMessagesEnabled,
            isBuilderSideTimeLineEnabled: company.isBuilderSideTimeLineEnabled,
            builderTimelineURL: company.builderTimelineURL,
            activeToInactiveAfterDays: company.activeToInactiveAfterDays,
            onHoldToActiveAfterDays: company.onHoldToActiveAfterDays,
            roleAllowedToManipulateFolders: company.roleAllowedToManipulateFolders,
        })

        //return what the cloud function returned
        return result
    } catch (error) {
        throw error
    }
}

export async function testBuildersideTimeline(companyID: string, buildersideTimelineURL: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncTestBuilderTimeline')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            buildersideTimelineURL,
        })

        //return what the cloud function returned
        return result.data
    } catch (error) {
        throw error
    }
}

//Subscriptions functions
export async function createSetupIntent(companyID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncStripeCreateSetupIntent')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
        })

        //return what the cloud function returned
        return result.data
    } catch (error) {
        throw error
    }
}

export async function switchSubscription(companyID: string, plan: string, coupon?: string, paymentMethodID?: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncStripeSwitchSubscription')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            plan,
            coupon,
            paymentMethodID,
        })

        //return what the cloud function returned
        return result
    } catch (error) {
        throw error
    }
}

export async function getSubscription(companyID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncStripeGetSubscriptions')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
        })

        //return what the cloud function returned
        return result.data as any
    } catch (error) {
        throw error
    }
}

export async function getAllPaymentMethods(companyID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncStripeRetrieveAllCards')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
        })

        //return what the cloud function returned
        return result.data as any
    } catch (error) {
        throw error
    }
}

export async function getInvoices(companyID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncStripeRetrieveInvoices')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
        })

        //return what the cloud function returned
        return result.data as any
    } catch (error) {
        throw error
    }
}

export async function getPricingPlans() {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncStripeGetPricingPlans')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction()

        //return what the cloud function returned
        return result.data as any
    } catch (error) {
        throw error
    }
}

//analytics functions
export async function getUsersForAnalytics(companyID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncRetrieveUserAnalytics')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
        })

        //return what the cloud function returned
        return result.data as Types.User[]
    } catch (error) {
        throw error
    }
}

export async function getCompanyViewsForAnalytics(companyID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncRetrieveCompanyAnalytics')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
        })

        //return what the cloud function returned
        return result.data as Types.CompanyAnalytics
    } catch (error) {
        throw error
    }
}

export async function getUserAnalyticsReport(companyID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncRetrieveUserAnalyticsReport')

        //call the function with the parms
        await cloudfunction({
            companyID,
        })

        //return what the cloud function returned
        return
    } catch (error) {
        throw error
    }
}

export async function getProjectAnalyticsReport(companyID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncRetrieveProjectAnalyticsReport')

        //call the function with the parms
        await cloudfunction({
            companyID,
        })

        //return what the cloud function returned
        return
    } catch (error) {
        throw error
    }
}


//folder functions
export async function getAllFolders(companyID: string) {
    try {
        const allFolders = await FirebaseManager.getAllFolders(companyID)

        return allFolders
    } catch (error) {
        throw error
    }
}

export async function createFolder(companyID: string, userUID: string, folderName: string, parentFolderID?: string, folderPhotoFile?: Types.CustomFile) {
    try {
        const folders = await createFolderHandler(companyID, folderName, parentFolderID)

        const folder = folders.filter(folder => (folder.name === folderName && folder.parentID === parentFolderID)).shift()

        if (folderPhotoFile && folderPhotoFile.file) {
            //there is a folderPhotoFile so save it

            // Points to the root reference
            const storageRef = firebase.storage().ref()

            // Points to this project's own image folder
            const folderImageFolderRef = storageRef.child('folderMainAssetTemp/' + folderPhotoFile.file.name)

            const customMetadata: any = {
                companyID: companyID,
                userToken: userUID,
            }

            if (folder) {
                customMetadata.folderID = folder.id
            }

            // Create file metadata including the content type
            const metadata: firebase.storage.UploadMetadata = {
                customMetadata,
            }

            await folderImageFolderRef.put(folderPhotoFile.file, metadata)
        }

        return folders
    } catch (error) {
        throw error
    }
}

const createFolderHandler = async (companyID: string, folderName: string, parentFolderID?: string) => {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncCreateFolder')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            folderName,
            parentFolderID,
        })

        //return what the cloud function returned
        return result.data as Types.Folder[]
    } catch (error) {
        throw error
    }
}

export async function editFolder(companyID: string, userUID: string, folderID: string, folderName: string, folderPhotoFile?: Types.CustomFile) {
    try {

        const folders = await editFolderHandler(companyID, folderID, folderName)

        if (folderPhotoFile && folderPhotoFile.image) {
            if (!folderPhotoFile.image.includes(process.env.REACT_APP_STORAGE_DOMAIN!)) {
                //if the image has already been uploaded, nothing to do
                //but if it gets here that means it still needs to be uploaded.

                // Points to the root reference
                const storageRef = firebase.storage().ref()

                // Points to this folder's own image folder
                const folderImageFolderRef = storageRef.child('folderMainAssetTemp/' + folderPhotoFile.file!.name)

                // Create file metadata including the content type
                const metadata: firebase.storage.UploadMetadata = {
                    customMetadata: {
                        companyID: companyID,
                        folderID: folderID,
                        userToken: userUID,
                    },
                }

                await folderImageFolderRef.put(folderPhotoFile.file!, metadata)
            }
        }

        return folders
    } catch (error) {
        throw error
    }
}

const editFolderHandler = async (companyID: string, folderID: string, folderName: string) => {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncEditFolder')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            folderID,
            folderName,
        })

        //return what the cloud function returned
        return result.data as Types.Folder[]
    } catch (error) {
        throw error
    }
}

export async function deleteFolder(companyID: string, folderID: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncDeleteFolder')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            folderID,
        })

        //return what the cloud function returned
        return result.data as Types.Folder[]
    } catch (error) {
        throw error
    }
}

export async function moveFolder(companyID: string, folderID: string, destinationFolderID?: string) {
    try {
        //create the Ref to the cloud callable function.
        const cloudfunction = firebase.functions().httpsCallable('firebasefuncMoveFolder')

        //call the function with the parms
        const result: firebase.functions.HttpsCallableResult = await cloudfunction({
            companyID,
            folderID,
            destinationFolderID,
        })

        //return what the cloud function returned
        return result.data as Types.Folder[]
    } catch (error) {
        throw error
    }
}