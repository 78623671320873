import { Fragment, FC } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
    Check as ActiveIcon,
    ArrowUpward as InactiveIcon,
    Add as AddIcon,
    ArrowForward as ArrowIcon,
    Send as SendIcon,
    Delete as DeleteIcon,
    Save as SaveIcon,
    FileCopy as CopyICon,
} from '@material-ui/icons'

const useStyles = makeStyles(({ shape, breakpoints, direction, mixins, overrides, palette, props, shadows, spacing, transitions, typography, zIndex }: Theme) => ({
    icon: {
        color: palette.primary.main,
        fontSize: 18,
        marginTop: 3,
    },
    progressCircle: {
        marginTop: 3,
    },
    button: (props: any) => ({
        borderRadius: 25,
        paddingTop: props.variant === 'outlined' ? undefined : 3,
        paddingBottom: props.variant === 'outlined' ? undefined : 3,
        margin: props.width === '100%' ? spacing(0) : spacing(1),
        color: props.destructive ? 'red' : props.buttonColor ? props.buttonColor : props.variant === 'outlined' || props.variant === 'text' ? palette.primary.main : 'white',
        borderColor: props.destructive ? 'red' : props.buttonColor ? props.buttonColor : undefined,
        width: props.width,
        height: props.height,
    }),
    dot: (props: any) => ({
        position: 'absolute',
        left: !props.centerProgress ? 4 : undefined,
        height: !props.centerProgress ? 25 : undefined,
        width: !props.centerProgress ? 25 : undefined,
        display: props.centerProgress ? 'flex' : undefined,
        backgroundColor: !props.centerProgress ? 'rgba(255, 255, 255)' : undefined,
        borderRadius: '50%',
    }),
    dotIcon: {
        color: palette.primary.main,
        fontSize: 18,
        marginTop: 3,
    },
    hiddenDot: {
        height: 25,
        width: 25,
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderRadius: '50%',
    },
}))

interface CustomButtonProps {
    disabled?: boolean
    processing?: boolean
    onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    title: string
    processingTitle?: string
    destructive?: boolean
    variant?: 'text' | 'outlined' | 'contained'
    size?: 'small' | 'medium' | 'large'
    caseIcon?: 'active'| 'add'| 'create'| 'continue'| 'send'| 'delete'| 'save'| 'copy'
    processingCaseIcon?: 'inactive'
    height?: string
    width?: string
    centerProgress?: boolean
    buttonColor?: string
}

const CustomButton: FC<CustomButtonProps> = ({
    disabled = false,
    processing = false,
    onClick,
    title,
    processingTitle,
    destructive = false,
    variant = 'contained',
    size = 'medium',
    caseIcon,
    processingCaseIcon,
    height,
    width,
    centerProgress = false,
    buttonColor,
}) => {
    const classes = useStyles({
        destructive,
        height,
        width,
        centerProgress,
        variant,
        buttonColor,
    })

    let icon = null
    switch (caseIcon) {
        case 'active':
            icon = <ActiveIcon className={classes.icon} />
            break
        case 'add':
            icon = <AddIcon className={classes.icon} />
            break
        case 'continue':
            icon = <ArrowIcon className={classes.icon} />
            break
        case 'send':
            icon = <SendIcon className={classes.icon} />
            break
        case 'delete':
            icon = <DeleteIcon className={classes.icon} />
            break
        case 'save':
            icon = <SaveIcon className={classes.icon} />
            break
        case 'create':
            icon = <AddIcon className={classes.icon} />
            break
        case 'copy':
            icon = <CopyICon className={classes.icon} />
            break

        default:
            break
    }

    let processingIcon = processingCaseIcon ? 
        <InactiveIcon className={classes.icon} />
        : 
        <CircularProgress size={centerProgress ? 32 : 20} thickness={2} className={classes.progressCircle} color="primary" />
 

    return (
        <Button
            variant={variant}
            size={size}
            color={processing ? 'default' : 'primary'}
            onClick={onClick}
            className={classes.button}
            disabled={disabled || processing}
        >
            {centerProgress ? (
                <Fragment>
                    <div className={classes.dot}>
                        {processing ? processingIcon : icon}
                    </div>
                </Fragment>
            ) : (icon && (
                <Fragment>
                    <div className={classes.dot}>
                        {processing ? processingIcon : icon}
                    </div>
                    <div className={classes.hiddenDot}></div>
                </Fragment>
            )
                )}
            {processing ? processingTitle : title}
        </Button>
    )
}

export default CustomButton
