import * as NetworkManager from 'api/NetworkManager'
import * as Types from 'resources/types'
import { zeroOutApiCall, beginApiCall, apiCallError } from './apiStatusActions'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

export type companyActions =
    | { type: 'SET_CURRENT_COMPANY'; companyID: string; sameCompany: boolean }
    | { type: 'GET_COMPANIES_INFO_SUCCESS'; companies: Types.Company[] }
    | { type: 'SET_CURRENT_STATE_SIGNNED_OUT' }
    | { type: 'UPDATE_CURRENT_COMPANY'; updatedCompany: Types.Company }
    | { type: 'ADD_COMPANY_CODE_TO_USER'; newCompany: Types.Company }
    | { type: 'BUILDERSIDE_TIMELINE_TEST_SUCCESS'; didTestPass: boolean; buildersideTimelineURL: string }
    | { type: 'GET_COMPANIES_ANALYTICS_SUCCESS'; analytics: Types.CompanyAnalytics }

export const setCurrentCompanySuccess = (companyID: string, sameCompany: boolean) => {
    return { type: 'SET_CURRENT_COMPANY', companyID, sameCompany }
}

export const getCompaniesInfoForUserSuccess = (companies: Types.Company[]) => {
    return { type: 'GET_COMPANIES_INFO_SUCCESS', companies }
}

export const setSignedOutState = () => {
    return { type: 'SET_CURRENT_STATE_SIGNNED_OUT' }
}

export const testBuildersideTimelineSuccess = (didTestPass: boolean, buildersideTimelineURL: string) => {
    return { type: 'BUILDERSIDE_TIMELINE_TEST_SUCCESS', didTestPass, buildersideTimelineURL }
}

export const updateCurrentCompany = (updatedCompany: Types.Company) => {
    return { type: 'UPDATE_CURRENT_COMPANY', updatedCompany }
}

export const getCompanyAnalyticsSuccess = (analytics: Types.CompanyAnalytics) => {
    return { type: 'GET_COMPANIES_ANALYTICS_SUCCESS', analytics }
}

export const updateCompanyInfroamtion = ( company: Types.Company ): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        try {
            dispatch(beginApiCall())
            await NetworkManager.updateCompany(company)
            dispatch(updateCurrentCompany(company))
        } catch (error) {
            dispatch(apiCallError())
            throw error
        }
    }
}

export const getOfficesForUser = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async ( dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any ): Promise<void> => {
        try {
            const offices = await NetworkManager.getOfficesForUser()
            dispatch(getCompaniesInfoForUserSuccess(offices))
        } catch (error) {
            throw error
        }
    }
}

export const setCurrentCompany = ( companyID: string ): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async ( dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any ): Promise<void> => {
        try {
            const {companies}: { companies: Types.companyReduxState } = getState()
            const sameCompany = companies.currentCompany ? companies.currentCompany.id === companyID : false

            //just to reset the api calls to zero
            dispatch(zeroOutApiCall())

            dispatch(setCurrentCompanySuccess(companyID, sameCompany))
        } catch (error) {
            throw error
        }
    }
}

export const testBuildersideTimeline = (buildersideTimelineURL: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        try {
            const {companies}: { companies: Types.companyReduxState } = getState()
            dispatch(beginApiCall())

            const result = await NetworkManager.testBuildersideTimeline(companies.currentCompany?.id!, buildersideTimelineURL)

            dispatch(testBuildersideTimelineSuccess(result, buildersideTimelineURL))
        } catch (error) {
            throw error
        }
    }
}

export const getCompanyAnalytics = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        try {
            const {companies}: { companies: Types.companyReduxState } = getState()
            dispatch(beginApiCall())

            const analytics = await NetworkManager.getCompanyViewsForAnalytics(companies.currentCompany?.id!)

            dispatch(getCompanyAnalyticsSuccess(analytics))
        } catch (error) {
            throw error
        }
    }
}
